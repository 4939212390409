












































































































































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';
 

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import _ from "lodash"
import test from '@/assets/test.json'
import {
    Web
} from '@/store/web'
@Component({
    components: {
         
    },
})
export default class Home extends Vue {
    private dashboard: any = null
    private user: any = {}
    private years: any = 0
    private year:any = {}
    private data: any = null
    private nullData: boolean = false
    private loadNum: number = 1

    private e1: number = 1
    private response: boolean = false
    private suggestion: string = ''
    private answer: any = [];
    private ansIndex: number = 0;
    private resultAll: any = [];
    private tmp: any = 0
    appSetting: any = {}
   
    private tmpChoice: any = [];
    $vs: any
    private async addValue(val: any, indexIssue: number, index: number) {
        let e: any = document.getElementById(val);
        if (e.options[e.selectedIndex].value) {
            let checkNotNull = await this.tmpStore(indexIssue, index)
            console.log(checkNotNull);

            let value: any = e.options[e.selectedIndex].value;
            let text: any = e.options[e.selectedIndex].text;
            this.answer.push(JSON.parse(value))
            this.answer = _.uniqBy(_.reverse(this.answer), function (e: any) {
                return e.id;
            });
            if (checkNotNull) {
                this.tmp++
            }

            await this.show()
        }
    }

    private async tmpStore(indexIssue: number, index: number) {
        if (!_.find(this.tmpChoice, {
                "index": index,
                "indexIssue": indexIssue
            })) {
            this.tmpChoice.push({
                "indexIssue": indexIssue,
                "index": index
            });
            return true
        } else {
            return false
        }
    }

    private async show() {
        let xx: any = this.answer;
        xx = await _.chain(xx)
            .groupBy('group')
            .map((value: any, key: any) => ({
                group: key,
                value: value
            }))
            .value();
        this.resultAll = xx
        console.log(xx)
    }

    private async saveAnswer() {
        await Web.switchLoad(true);
        for (let index = 0; index < this.answer.length; index++) {
            let form = {
                "value": this.answer[index].value,
                "value_type": this.answer[index].value_type,
                "value_by": this.answer[index].value_by,
                "user": this.user.pk,
                "assessmentIssues": this.answer[index].assessment,
                "agency": this.user.ext_link.agency,
                "year": this.years,
                "issue": this.answer[index].group,
                "issueDetail": this.answer[index].id
            }
            await Core.postHttp(`/api/iit/v2/answerissue/`, form)
        }
        await Core.postHttp(`/api/iit/v2/answersuggestion/`, {
            "suggestion": this.suggestion,
            "user": this.user.pk,
            "agency": this.user.ext_link.agency,
            "year": this.years,
        })
        await this.storeUserInAnswer();
        await Web.switchLoad(false);
        await this.openNotification('top-right', 'success', `<i class="em em-smiley" aria-role="presentation" aria-label="SMILING FACE WITH OPEN MOUTH"></i>`, 'ประเมินสำเร็จแล้ว', '')
       let check = confirm('การประเมิน IIT สำเร็จแล้ว คุณต้องการทำแบบประเมิน EIT ต่อหรือไม่')
       if(check){
           await this.$router.push('/eit/year')
       }else{
await this.$router.go(-1)
       }
       // 

    }

    @Watch('e1')
    changeTab(value: any, oldValue: any) {
        while (this.tmpChoice.length > 0) {
            this.tmpChoice.pop();
        }
    }

    public async created() {
        //  await this.show();
        await Web.switchLoad(true);
        this.user = await User.getUser();
        this.years = this.$route.query.year
        this.year = await Core.getHttp(`/api/iit/v2/year/${this.years}/`)
        this.data = await Core.getHttp(`/api/iit/v1/issue?year=${this.years}`)
        if (this.data.length == 0) {
            this.nullData = true
        }
        try {
            this.appSetting = await Core.getSetting(this.year.year)
        } catch (error) {
            this.appSetting = null
        }
        await Web.switchLoad(false);
        this.response = true
    }

    async sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async toTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    async storeUserInAnswer() {
        let form = {
            "user": this.user.pk,
            "agency": this.user.ext_link.agency,
            "year": this.years
        }
        await Core.postHttp(`/api/iit/v2/ansewer/user/`, form)
    }
    getDataLoad(num: number) {
        // this.loadNum = num 
        return num;
    }
    async openNotification(position: any = null, color: any, icon: any, title: string, text: string) {

        const noti = this.$vs.notification({
            icon,
            color,
            position,
            title: title,
            text: text
        })
    }

    getChoice(choice:any, index:any, defChoices:any){
        try {
            let choices = this.appSetting.iit_choices
        let result = _.find(choices, item => _.includes(item.index, choice));
        if (result) {
            return result.choices[index]
        } else {
            return defChoices
        } 
        } catch (error) {
            return defChoices
        }
    }
}
